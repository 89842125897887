import { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { ConcentWrapper } from "styles";
import { MAIN_URL } from "util/consts";

export const CookieConsent = ({ formURL }) => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem(`cookieConsent.${formURL}`);
    if (!consent) {
      setShowConsent(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleAccept = () => {
    localStorage.setItem(`cookieConsent.${formURL}`, "accepted");
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <ConcentWrapper>
      <div>
        <h6>Cookie & Data Usage Notice</h6>
        <p>
          We use cookies and local storage to ensure a smooth experience, track
          form progress, and analyze usage. By continuing, you agree to this.{" "}
          <a
            href={`${MAIN_URL}privacy-policy/`}
            rel="noreferrer"
            target="_blank"
          >
            Read more
          </a>
        </p>
      </div>

      <Button primary size="small" onClick={handleAccept}>
        Accept & Continue
      </Button>
    </ConcentWrapper>
  );
};
