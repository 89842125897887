import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "components/App/App";

if (process.env.IS_EMBED_APP) {
  window.renderEmbeddedForm = (containerId, props = {}) => {
    const container = document.getElementById(containerId);
    if (!container) {
      console.error(`Container #${containerId} not found.`);
      return;
    }

    const root = ReactDOM.createRoot(container);
    root.render(<App {...props} />);
  };
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }
}
